import { axiosInstance } from '../../helpers/axios';
import * as Cookies from 'js-cookie';
var CryptoJS = require("crypto-js");
import router from '../../router'
export const state = {
    isAuth:false,
    token:"",
    rl: "",
    reportingpage: "",
    yetkiler:[],
    reportinglimit:10,
}

export const mutations = {

    loginSuccess(state, user){
        state.isAuth = true;
        state.token = user.token;
        state.rl = user.rl;
    },
    setToken(state, token){
        state.isAuth = true;
        state.token = token;
    },
    loginFailure (state){
        state.isAuth = false;
        state.token = "";
        state.yetkiler = [];
    },
    logout (state) {
        state.isAuth = false;
        state.token = null;
        state.yetkiler = [];
    },
    setRL(state, rl){
        state.rl = rl;
    },
    
}

export const getters = {
    isAuthenticated(state) {
        if(state.token!="")
        {
            return true;
        }else{
            return false;
        }
    },
    rl(state) {
        var bytes  = CryptoJS.AES.decrypt(state.rl,"Ty._?2819372893791");
        var originalText = bytes.toString(CryptoJS.enc.Utf8);
        return  originalText;
    },
   
   
}

export const actions = {
    
    login({ commit, dispatch }, { kullaniciadi, password } = {}) {

        return new Promise((resolve, reject) => {
        axiosInstance.post("users/authenticate", {
            email: kullaniciadi,
            password: password
        }).then(function (response) {
            if (response.data.error == true) {

                commit("loginFailure");
                reject(response.data.message);
            } else {
                commit("loginSuccess", response.data);
                axiosInstance.defaults.headers.common['Authorization'] = "Bearer " + response.data.token;
                localStorage.setItem('token', response.data.token);
                localStorage.setItem('rl', response.data.rl);
                localStorage.setItem('user', response.data.user.username);
                dispatch("PermissionAction",response.data.token);
                resolve(response.data);
            }
        }).catch(function() {
            commit("loginFailure");
            reject("Hata oluştu.Lütfen daha sonra tekrar deneyiniz");
        });
    })
        
    },
    logout({ commit }){
        commit('logout');
        Cookies.remove("vuex");
        localStorage.removeItem('token');
        localStorage.removeItem('rl');
        localStorage.removeItem('expirationDate');
        localStorage.removeItem('user');
        router.push("/giris");
    },
    
    
   
}




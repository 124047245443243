//import Store from '../state/store'
const routes = [{
        path: '/dashboard',
        name: 'home',
        component: () => import('../views/pages/dashboard/index'),

    },{
        path: '/users/list',
        name: 'users-list',
        component: () => import('../views/pages/user/list'),

    },{
        path: '/users/create',
        name: 'users-create',
        component: () => import('../views/pages/user/create'),

    },
    {
        path: '/giris',
        name: 'giris',

        component: () => import('../views/pages/giris/login')
    },
    {
        path: '/kullanici-olustur',
        name: 'kullanici-olustur',

        component: () => import('../views/pages/giris/register')
    },
    {
        path: '/profil-ayarlari',
        name: 'profil-ayarlari',

        component: () => import('../views/pages/giris/profilayarlari')
    },




]





export default routes;